import "./Register.css";
import React, {SyntheticEvent, useState} from "react";
import {Navigate} from "react-router-dom";
import {toast} from "react-toastify";
import axios from 'axios';



function Register()  {
    const [name, setName] = useState('');
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');



    const [redirect, setRedirect] = useState(false);



    const registerSubmit = (e) => {
        e.preventDefault();

        const data = {
            name: name,
            email: email,
            password: password
        };

        axios.post(`api/v1.0/register`, data).then(res => {
            if(res.status === 201){
                toast.success('Account succesvol aangemaakt. U wordt nu doorgestuurd naar de login pagina.')
                setRedirect(true);
            } else{
                toast.error('Er is iets misgegaan, probeer het later opnieuw.', {position: toast.POSITION.TOP_RIGHT});
            };
        });
    }

        if(redirect){
            return <Navigate to="/login" />
        }

    return(

            <main className="form-signin">
                <form onSubmit={registerSubmit}>
                    <h1 className="h3 mb-3 fw-normal">Please sign up</h1>
                    <div className="form-floating">
                        <input type="text" className="form-control" placeholder="name" required autoFocus
                                onChange={e=> setName(e.target.value)}
                        />
                        <label htmlFor="floatingInput">Name</label>
                    </div>
                    <div className="form-floating">
                        <input type="email" className="form-control" placeholder="name@example.com" required
                                onChange={e=> setEmail(e.target.value)}
                        />
                        <label htmlFor="floatingInput">Email address</label>
                    </div>
                    <div className="form-floating">
                        <input type="password" className="form-control" placeholder="Password" required
                                onChange={e=> setPassword(e.target.value)}
                        />
                        <label htmlFor="floatingPassword">Password</label>
                    </div>
                    <button className="w-100 btn btn-lg btn-primary" type="submit">Sign up</button>
                    <p className="mt-5 mb-3 text-muted">© 2017–2022</p>
                </form>
            </main>
    );
    };



export default Register;
