import "./Home.css";
import React from "react";
import user from "../../models/auth/user";


function Home() {
  console.log(user.permissions);

  return (
    <div className="home">
      <h1>Home</h1>
        <h2> {user.name ? 'Welcome ' + user.name : 'Jij bent niet ingelogd.'}</h2>
        <h3>{ (typeof user.permissions !== []) ?
        user.permissions : "Geen rechten"}</h3>
    </div>
  );
};

export default Home;
