import './App.css';
import 'react-toastify/dist/ReactToastify.css'
import LoginForm from "./components/LoginForm/LoginForm";
import NavBar from "./components/NavBar/NavBar";
import {BrowserRouter, Route, Routes} from "react-router-dom";
import Register from "./components/Register/Register";
import Home from "./components/Home/Home";
import { toast } from 'react-toastify';
import { useState} from "react";
import PayMerchantTable from "./components/PayMerchants/PayMerchantTable/PayMerchantTable";
import axios from "axios";
import PayMerchantWizard from "./components/PayMerchants/PayMerchantWizard/PayMerchantWizard";

toast.configure();
axios.defaults.withCredentials = true;
axios.defaults.baseURL = "http://localhost:8000/";
axios.defaults.headers.post['Accept'] = "application/json";
axios.defaults.headers.post['Content-type'] = "application/json";
axios.defaults.headers.get['Access-Control-Allow-Origin'] = "*";
axios.defaults.headers.get['Content-type'] = "application/json";


function App() {
    const [name, setName] = useState('');
    const [permissions, setPermissions] = useState([]);



  return (
    <div className="App">
        <BrowserRouter>
        <NavBar/>
            <Routes>
                <Route path="/" element={<Home />} />
                <Route path="/login" element={<LoginForm /> } />
                <Route path="/Paymerchants" element={<PayMerchantTable /> }/>
                <Route path="/register" element={<Register />} />
                <Route path="/Paymerchants/new" element={<PayMerchantWizard />} />

            </Routes>

        </BrowserRouter>
    </div>
  );
}

export default App;
