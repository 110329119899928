class User {

    constructor() {
        this.init()
    }

    init() {
        this.name = localStorage.getItem('userName')
        this.email = localStorage.getItem('userEmail')
        this.permissions = localStorage.getItem('userPermissions')
        this.roles = localStorage.getItem('userRoles')
        this.loggedIn = localStorage.getItem('userLoggedIn')
    }

    /**
     *
     * @param data object
     * @param data.name string
     * @param data.email string
     * @param data.permissions []
     * @param data.roles []
     * @param callback function
     */
    authenticated(data, callback) {
        localStorage.setItem('userName', data.name)
        localStorage.setItem('userEmail', data.email)
        localStorage.setItem('userLoggedIn', true)
        localStorage.setItem('userPermissions', data.permissions)
        localStorage.setItem('userRoles', data.roles)

        this.init()

        callback()
    }

    /**
     *
     * @return {boolean}
     */
    isLoggedIn() {
        return Boolean(this.loggedIn) === true
    }

    hasPermission(permission: string) {
        if(this.isLoggedIn()){
            if(this.permissions.indexOf(permission) > -1){
                return true;
            }
        }
        return false;
    }

    logout(){
        localStorage.removeItem('userName')
        localStorage.removeItem('userEmail')
        localStorage.removeItem('userLoggedIn')
        localStorage.removeItem('userPermissions')
        localStorage.removeItem('userRoles')

        this.init()
    }
}

export default new User()