import "./PayMerchantTable.css";
import React, {useEffect, useMemo, useState} from "react";
import '@inovua/reactdatagrid-community/index.css'
import axios from "axios";
import {useTable} from "react-table";

function PayMerchantTable() {
    const [isLoading, setLoading] = useState(true);
    const [payMerchants, setPayMerchants] = useState([]);


    useEffect(() => {
        axios.get("/api/v1.0/PayMerchants").then(response => {
            setPayMerchants(response.data.data);
            setLoading(false);
        });
    }, []);


    if (isLoading) {
        return <div className="loading"><br /><br /><br />Loading...</div>;
    }

    return (
        <div style={{height: 400}} className="paymerchant-table">
            <table>
                <thead>
                    <tr>
                        <td>Resellernr.</td>
                        <td>Naam</td>
                        <td>E-mail</td>
                    </tr>
                </thead>
            <tbody>
            {payMerchants.map((payMerchant) =>
                <tr key={payMerchant.id}>
                    <td>{payMerchant.id}</td>
                    <td>{payMerchant.name}</td>
                    <td>{payMerchant.contactEmail}</td>
                </tr>
            )}
            </tbody>
            </table>
        </div>
    );
};

export default PayMerchantTable;
