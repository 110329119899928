import React, { Fragment, useState, useEffect } from 'react';
import StepWizard from "react-step-wizard";
import NavBar from "../../NavBar/NavBar";
import axios from "axios";
import {toast, ToastContainer} from "react-toastify";
/* eslint react/prop-types: 0 */

/**
 * A basic demonstration of how to use the step wizard
 */
const PayMerchantWizard = () => {
    const [state, updateState] = useState({
        form: {
            companyName: '',
            cocNumber: '',
        },
        demo: true, // uncomment to see more
        paymerchant: {},
        loading: false
    });


    const updateForm = (key, value) => {
        const { form } = state;

        form[key] = value;
        updateState({
            ...state,
            form,
        });
    };

    const updatePaymerchant = (key, value) => {
        const { paymerchant } = state;

        paymerchant[key] = value;
        updateState({
            ...state,
            paymerchant,
        });
    };

    // Do something on step change
    const onStepChange = (stats) => {
        // console.log(stats);
    };

    const setInstance = SW => updateState({
        ...state,
        SW,
    });

    const { SW, demo } = state;

    return (
        <div className='container'>
            <h3>React Step Wizard</h3>
            <div className={'jumbotron'}>
                <div className='row'>
                    <div className={`col-12 col-sm-6 offset-sm-3 }`}>
                        <StepWizard
                            onStepChange={onStepChange}
                            isHashEnabled
                            transitions={state.transitions} // comment out for default transitions
                            nav={<NavBar />}
                            instance={setInstance}
                        >
                            <First hashKey={'Bedrijfsgegevens'} update={updateForm} form={state.form}/>
                            <Second hashKey={'Accountgegevens'} update={updateForm} form={state.form} />
                            <Third hashKey={'Bankgegevens'} update={updateForm} form={state.form} />
                            <Progress update={updateForm} form={state.form} stepName='progress' />
                            {null /* will be ignored */}
                            <Last hashKey={'TheEnd!'} />
                        </StepWizard>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default PayMerchantWizard;

/**
 * Stats Component - to illustrate the possible functions
 * Could be used for nav buttons or overview
 */
const Stats = ({
                   currentStep,
                   firstStep,
                   goToStep,
                   lastStep,
                   nextStep,
                   previousStep,
                   totalSteps,
                   step,
               }) => (
    <div>
        <hr />
        { step > 1 &&
        <button className='btn btn-default btn-block' onClick={previousStep}>Stap terug</button>
        }
        { step < totalSteps ?
            <button className='btn btn-primary btn-block' onClick={nextStep}>Volgende</button>
            :
            <button className='btn btn-success btn-block' onClick={nextStep}>Voltooien</button>
        }
        <hr />
        <div style={{ fontSize: '21px', fontWeight: '200' }}>
            <h4>Other Functions</h4>
            <div>Current Step: {currentStep}</div>
            <div>Total Steps: {totalSteps}</div>
            <button className='btn btn-block btn-default' onClick={firstStep}>First Step</button>
            <button className='btn btn-block btn-default' onClick={lastStep}>Last Step</button>
            <button className='btn btn-block btn-default' onClick={() => goToStep(2)}>Go to Step 2</button>
        </div>
    </div>
);

/** Steps */

const First = props => {
    const update = (e) => {
        props.update(e.target.name, e.target.value);
    };

    const validate = () => {
        props.nextStep();
        const fields = ['companyName', 'cocNumber','street','houseNumber','postalCode', 'place'];
        fields.forEach((value, index, array) => {
            if(document.getElementsByName(value)[0].value === ''){
                toast.error("Je moet het veld " + value + " nog invullen.");
                props.goToStep("Bedrijfsgegevens");
            }
        });

    };


    return (
        <div>
            <h3 className='text-center'>Bedrijfsgegevens</h3>

            <div className={'row g-3'}>

            <div className={'col-md-12'}>
                <label>Bedrijfsnaam</label>
                <input type='text' className='form-control' value={props.form.companyName} name='companyName' placeholder='Bedrijfsnaam' required
                       onChange={update} />
            </div>
                <div className={'col-md-12'}>
                    <label>KVK Nummer</label>
                    <input type='text' className='form-control' value={props.form.cocNumber} name='cocNumber' placeholder='KVK Nummer' required
                           onChange={update} />
                </div>
            <div className={'col-md-10'}>
            <label>Straat</label>
            <input type='text' className='form-control'value={props.form.street} name='street' placeholder='Straatnaam' required
                   onChange={update} />
            </div>
            <div className={'col-md-2'}>
                <label>Huisnr.</label>
                <input type='text' className='form-control' name='houseNumber' placeholder='nummer' required
                   onChange={update} />
            </div>
                <div className={'col-md-4'}>
                    <label>Postcode</label>
                    <input type='text' className='form-control' name='postalCode' placeholder='Postcode' required
                           onChange={update} />
                </div>
                <div className={'col-md-8'}>
                    <label>Plaats</label>
                    <input type='text' className='form-control' name='city' placeholder='Plaats'
                           onChange={update} />
                </div>
            </div>

            <Stats step={1} {...props} nextStep={validate} />
        </div>
    );
};

const Second = props => {
    const update = (e) => {
        props.update(e.target.name, e.target.value);
        props.form.email = 'support+' + props.form.companyName.replace(/\s/g, "") + '@sphosting.nl'
    };

    const validate = () => {
        props.nextStep();
        const fields = ['email', 'firstname','firstname','placeOfBirth','dateOfBirth', 'gender', 'authorisedToSign', 'ubo', 'uboPercentage'];
        fields.forEach((value, index, array) => {
            if(document.getElementsByName(value)[0].value === ''){
                toast.error("Je moet het veld " + value + " nog invullen.");
                props.goToStep("Accountgegevens");
            }
        });

    };

    return (
        <div>
            <h3 className='text-center'>Accountgegevens</h3>

            <div className={'row g-3'}>

                <div className={'col-md-12'}>
                    <label>E-mail</label>
                    <input type='text' className='form-control-plaintext' name='email' value={props.form.email} required readOnly
                           onChange={update} />
                </div>
                <div className={'col-md-6'}>
                    <label>Voornaam</label>
                    <input type='text' className='form-control' name='firstname' placeholder='Voornaam' required
                           onChange={update} />
                </div>
                <div className={'col-md-6'}>
                    <label>Achternaam</label>
                    <input type='text' className='form-control' name='lastname' placeholder='Achternaam' required
                           onChange={update} />
                </div>
                <div className={'col-md-6'}>
                    <label>Geboorteplaats</label>
                    <input type='text' className='form-control' name='placeOfBirth' placeholder='Geboorteplaats' required
                           onChange={update} />
                </div>
                <div className={'col-md-6'}>
                    <label>Geboortedatum</label>
                    <input type='date' className='form-control' name='dateOfBirth' required
                           onChange={update} />
                </div>
                <div className={'col-md-4'}>
                    <label>Geslacht</label><br />
                    <select className="form-select" name='gender' onChange={update} required>
                        <option value='noChoiceMade'>Maak een keuze...</option>
                        <option value='male'>Man</option>
                        <option value='female'>Vrouw</option>

                    </select>
                </div>
                <div className={'col-md-8'}>
                    <label>Bevoegd om te tekenen</label>
                    <select className="form-select" name='authorisedToSign' onChange={update}>
                        <option value='noChoiceMade' >Maak een keuze...</option>
                        <option value='0'>Nee</option>
                        <option value='1'>Ja, volledig/zelfstandig tekenbevoegd</option>
                        <option value='2'>Ja, gezamenlijk tekenbevoegd</option>
                    </select>
                </div>
                <div className={'col-md-6'}>
                    <label>UBO</label>
                    <select className="form-select" name='ubo' onChange={update}>
                        <option value='noChoiceMade' >Maak een keuze...</option>
                        <option value='0'>Nee</option>
                        <option value='1'>Ja</option>
                    </select>
                </div>
                <div className={'col-md-6'}>
                    <label>UBO Percentage</label>
                    <input type='number' min='0' max='100' className='form-control' name='lastname' placeholder='Aantal invullen in percentage' required
                           onChange={update} />
                </div>
            </div>

            <Stats step={2} {...props} nextStep={validate}/>
        </div>
    );
};


const Third = props => {
    const update = (e) => {
        props.update(e.target.name, e.target.value);
    };

    return (
        <div>
            <h3 className='text-center'>Bankgegevens</h3>

            <div className={'row g-3'}>

                <div className={'col-md-12'}>
                    <label>Bankaccount</label>
                    <input type='text' className='form-control' name='bankAccountOwner' placeholder='Voornaam Achternaam' required
                           onChange={update} />
                </div>
                <div className={'col-md-12'}>
                    <label>IBAN</label>
                    <input type='text' className='form-control' name='bankAccountNumber' placeholder='NL00ABNA1234567890' required
                           onChange={update} />
                </div>
                <div className={'col-md-12'}>
                    <label>Bank</label>
                    <select className="form-select" name='bankAccountBIC' onChange={update}>
                        <option value='noChoiceMade' >Maak een keuze...</option>
                        <option value='INGBNL2A'>ING</option>
                        <option value='RABONL2U'>Rabo</option>
                        <option value='KNABNL2H'>Knab</option>
                        <option value='ABNANL2A'>ABN Amro</option>
                        <option value='BUNQNL2A'>Bunq</option>
                        <option value='SNSBNL2A'>SNS</option>
                        <option value='TRIONL2U'>Triodos</option>
                        <option value='ASNBNL21'>ASN</option>
                        <option value='RBRBNL21'>Regiobank</option>
                    </select>
                </div>

            </div>

            <Stats step={3} {...props}/>
        </div>
    );
};

const Progress = (props) => {
    const [state, updateState] = useState({
        isActiveClass: '',
        timeout: null,
    });

    const [isLoading, setLoading] = useState(true);

    const sendData = async () => {
        toast.info('Data versturen, wachten a.u.b.', {
            position: "top-center",
            autoClose: 10000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: false,
            draggable: true,
            progress: undefined,
        });
        axios.post('api/v1.0/sendToPay', props.form).then((res) => {
            console.log(res);

            setLoading(false);
        })
    }

        if (isLoading) {

            return <div className="loading">
            </div>;
    }

    return (
        <div className={''}>
            <p className='text-center'>Automated Progress...</p>
            <div className={''}>
                <div className={`progress-bar-striped`} />
            </div>
        </div>
    );
};

const Last = (props) => {
    const submit = () => {
        alert('You did it! Yay!') // eslint-disable-line
    };

    return (
        <div>
            <div className={'text-center'}>
                <h3>This is the last step in this example!</h3>
                <hr />
            </div>
            <Stats step={4} {...props} nextStep={submit} />
        </div>
    );
};