import "./LoginForm.css";
import React, {useState} from "react";
import {toast} from "react-toastify";
import {Navigate, useNavigate} from "react-router-dom";
import axios from "axios";
import user from "../../models/auth/user";



function LoginForm(props: { setName: (name: string) => void, setPermissions: (permissions: []) => void}) {
    let navigate = useNavigate();

    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [redirect, setRedirect] = useState(false);

    function authenticatedCallback(){
        toast.success('Je bent succesvol ingelogd, je wordt nu doorgestuurd.', {position: toast.POSITION.TOP_RIGHT});
        navigate("/");
    }

    const loginSubmit = (e) => {
        e.preventDefault();
        const data = {
            email: email,
            password: password
        }

        axios.post(`api/v1.0/login`, data).then(res => {
            if(res.status === 200){
                console.log(res.data.data);
                user.authenticated(res.data.data, authenticatedCallback);
            } else if (res.status === 401){
                toast.error('Onjuiste inloggegevens.', {position: toast.POSITION.TOP_RIGHT});
            }
            else{
                toast.error('Er is iets misgegaan, probeer het later opnieuw.', {position: toast.POSITION.TOP_RIGHT});
            }
        });
    }

    return (
      <main className="form-signin">
          <form onSubmit={loginSubmit}>
              <h1 className="h3 mb-3 fw-normal">Please sign in</h1>
              <div className="form-floating">
                  <input type="email" className="form-control" placeholder="name@example.com" required autoFocus
                         onChange={e => setEmail(e.target.value)}
                  />
                  <label htmlFor="floatingInput">Email address</label>
              </div>
              <div className="form-floating">
                  <input type="password" className="form-control" placeholder="Password" required
                         onChange={e => setPassword(e.target.value)}
                  />
                  <label htmlFor="floatingPassword">Password</label>
              </div>
              <button className="w-100 btn btn-lg btn-primary" type="submit">Sign in</button>
              <p className="mt-5 mb-3 text-muted">© 2017–2022</p>
          </form>
      </main>
  );
};

export default LoginForm;
