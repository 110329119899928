import "./NavBar.css";
import React from "react";
import {Link} from "react-router-dom";
import user from "../../models/auth/user";

function NavBar(props: {name: string, permissions: [], setName: (name: string) => void, setPermissions: (permissions: []) => void}) {


    const logout = async () => {
        await fetch("http://localhost:8000/api/v1.0/logout", {
            method: 'POST',
            headers: {
                'Content-type': 'applicaton/json',
            },
            credentials: 'include'
        });

        user.logout();

    };

    let menu;

    if(!user.isLoggedIn() ){
        menu = (
            <div>
                <ul className="navbar-nav me-auto mb-2 mb-lg-0">
                    <li className="nav-item">
                        <Link className="nav-link" to="/login">Login</Link>
                    </li>
                    <li className="nav-item">
                        <Link className="nav-link" to="/register">Register</Link>
                    </li>
                </ul>
            </div>
        )
    } else{
        menu = (
            <div>
                <ul className="navbar-nav me-auto mb-2 mb-lg-0">
                    <li className="nav-item">
                        <Link className="nav-link" to="/login" onClick={logout}>Logout</Link>
                    </li>
                </ul>
            </div>
        )
    }
  return (
      <nav className="navbar navbar-expand-lg fixed-top navbar-dark bg-dark" aria-label="Main navigation">
          <div className="container-fluid">
              <Link to="/" className="navbar-brand">Home</Link>
              {user.hasPermission("read.paymerchants") ? <Link className="nav-link" to="/Paymerchants" >PayMerchants</Link> : ""}

              {menu}
          </div>
      </nav>
  );
};

export default NavBar;
